<template>
    <div class="section-text-columns" :class="color">
        <layout-contained :class="padding">
            <content-heading
                v-if="heading"
                :heading="heading"
                :headingColor="headingColor"
                :headingComponent="headingComponent"
                :marginBottom="content ? 'mb-6' : 'mb-0'"
                :textCentered="textCentered"
            ></content-heading>
            <div class="md:grid md:grid-cols-2 md:gap-12">
                <content-text
                    v-if="contentColumn1"
                    :html="contentColumn1"
                    :contentColor="contentColor"
                    :textCentered="textCentered"
                ></content-text>
                <content-text
                    v-if="contentColumn2"
                    :html="contentColumn2"
                    :contentColor="contentColor"
                    :textCentered="textCentered"
                ></content-text>
            </div>
            <div v-if="buttonText" class="mt-8" :class="!!textCentered ? 'text-center' : ''">
                <button-primary
                    :buttonColor="buttonColor || undefined"
                    :buttonRoute="buttonRoute"
                    :buttonText="buttonText"
                    :buttonClick="buttonClick"
                    :buttonClickParams="buttonClickParams"
                ></button-primary>
            </div>
            <slot name="custom"></slot>
        </layout-contained>
    </div>
</template>

<script>
export default {
    name: 'SectionTextColumns',
    props: {
        padding: {
            required: false,
            default: 'py-12 sm:py-14',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: 'bg-zinc-800',
        },
        contentColumn1: {
            required: false,
            type: String,
            default: null,
        },
        contentColumn2: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-zinc-400',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-yellow-400',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
};
</script>
